.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  background-color: #ff5722 !important;
}

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  box-shadow: 5px 10px 18px #ced4da;
}
div.mini-drawer .app-main-header.custom-color {
  background: #f15931 !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.cbutton:hover {
  background: #f15931;
  color: #fff;
}

.app-sidebar .side-nav ul.nav-menu li a:hover {
  background: #5f5958;
  color: #fff;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #f15931 !important;
  color: #000;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #f15931;
}
.page-heading i.zmdi-hc-fw {
  color: #f15931;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #f15931 !important;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #f15931 !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #f15931 !important;
}

div#form-dialog-title {
  background: #f15931;
}

/* Status labels*/
span.label {
  background: gray;
}

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}

span.completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes,
span.success,
span.true {
  background: green;
}

span.cancelled,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no,
span.false {
  background: #fd2121;
}
span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}

.uper-multi-row {
  background: #00000017;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.react-time-picker {
  width: 100%;
  height: 32px;
  /* border-bottom: 1px solid #737373; */
}
.react-time-picker * {
  border: 0;
}
label.form-label {
  color: #737373;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
}

.table-responsive-material .blog-profile .user-avatar img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.user-profile.blog-profile .user-avatar {
  height: auto !important;
  width: auto !important;
}
.blog-detail-profile img {
  height: 120px;
  width: 120px;
}

.list {
  margin: 30px;
}
.list .item {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #f9593c;
  color: #fff;
  font-weight: 500;
}

.menulist .menuitem {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: green;
  color: #fff;
  font-weight: 500;
  margin: 20px;
}
.menulist .menuitem {
  margin-bottom: 20px;
}
.list .item:not(:last-child) {
  margin-bottom: 20px;
}
.vks .list {
  display: flex;
  gap: 30px;
}
.drag_drop h1 {
  padding: 10px;
  text-align: center;
  box-shadow: 1px 1px 5px #ccc;
  margin-top: 18px;
}
.no_data p {
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
}

.customizeModal .modal-content {
  max-width: 650px;
}

.common-btn {
  height: 40px;
  background: #f15931 !important;
  color: #fff !important;
}

.menulist .menuitem {
  height: auto;
  min-height: 40px;
  padding: 10px;
  background-color: #707070;
  left: 0 !important;
}
.menulist .menuitem.dragging,
.menulist .menuitem.draggingOver {
  top: 0 !important;
  position: relative !important;
}
.menulist .menuitem.draggingOver {
  top: unset !important;
  /* bottom : 0 !important */
}
.InnerCol .item.dragging {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.item {
  width: 100px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  /* Other styles */
  position: relative;
  z-index: 1; /* Adjust as needed */
  margin-bottom: 10px;
}

.menuitem {
  width: 100px; /* Adjust as needed */
  height: 50px;
  margin-bottom: 0px;
}
.dragging {
  z-index: 1000;
  cursor: grabbing;
  position: absolute;
  background-color: lightblue;
  opacity: 0.8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.scrollable-container {
  overflow-y: auto;
}
.container {
  overflow: hidden;
}

.vks .list .ItemWrpper {
  height: 50px;
}


.heading_timeslot li{
  list-style-type: none;
  width: 33.33%;
}


tr#monday-1 p ,tr#tuesday-2 p, tr#wednesday-3 p, tr#thursday-4 p, tr#friday-5 p, tr#saturday-6 p
{
  display: none;
}

tr#sunday-0 p{
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

